
class globalSearch {

    constructor() {

        this.timer = null;
        this.searchInput = $('#navbar-search input[name=globalSearch]');
        this.addEvents();
    }

    addEvents() {
        $("#navbar-search").submit(function(e) {
            e.preventDefault();
            if (this.searchInput.val().length < Number(this.searchInput.attr('minlength'))) {
                return;
            }

            if (this.searchInput.val()){
                $("#search-icon").removeClass('fa-magnifying-glass').addClass('fa-spinner fa-pulse');
                $("#search_results").html('<div class="search-result-row"><a href="#"><div class="float-start" style="color: #444; margin-left: 10px;"><i class="fa fa-spinner fa-pulse"></i></div><strong>Bezig met zoeken.... </strong></a></>');
                this.doSearch($("#navbar-search"));
            }
        }.bind(this));

        $("#navbar-search").keyup(function(e) {

            if (this.searchInput.val().trim().length < Number(this.searchInput.attr('minlength'))) {
                return;
            }

            if (this.searchInput.val()) {
                this.delay(function() {
                    this.searchInput.val(this.searchInput.val().trim());
                    $("#search-icon").removeClass('fa-magnifying-glass').addClass('fa-spinner fa-pulse');
                    $("#search_results").html('<div class="search-result-row"><a href="#"><div class="float-start" style="color: #444; margin-left: 10px;"><i class="fa fa-spinner fa-pulse"></i></div><strong>Bezig met zoeken .... </strong></a></div>');
                    this.doSearch(this.searchInput);
                }.bind(this), 500);
            }
        }.bind(this));

        $('.navbar-form-results').click(function(e){
            e.stopPropagation();
        }.bind(this));

        $('#navbar-search').click(function(e){
            if ($('#search-dropdown').hasClass('show')  || $('#search_results').is(':empty')){
                e.stopPropagation();
            }
        }.bind(this));
    }

    delay(callback) {
        clearTimeout(this.timer);
        this.timer = setTimeout(callback, 500);
    }

    doSearch(form) {
        $.ajax({
            type: "GET",
            url: "/admin/search/api/search",
            data: form.serialize(),
            cache: true,
            headers: {
                'Cache-Control': 'max-age=120'
            },
            success: function (results) {
                this.showSearchResults(form, results);
            }.bind(this),
            dataType: 'json'
        });
    }

    showSearchResults(form, resultsObj) {

        $("#search-icon").removeClass('fa-pulse').removeClass('fa-spinner').addClass('fa-magnifying-glass');
        $("#search-results").empty();
        $('#search-stats').html("<small><b>"+resultsObj['stats']['resultscount']+"</b> resultaten ("+resultsObj['stats']['time']+" seconden)</small>");

        if ($.isEmptyObject(resultsObj['results'])){
            $("#search-results").append(
                '<div class="search-result-row">' +
                    '<a href="#">' +
                        '<div class="search-result-icon">' +
                            '<i class="fa fa-ban fa-lg"></i>' +
                        '</div>' +
                        '<div class="search-result-text" style="margin-top: 10px;">' +
                            'Geen resultaten gevonden' +
                        '</div>' +
                    '</a>' +
                '</div>'
            );

        } else {
            $.each( resultsObj['results'], function( key, value ) {

                if (value['type'] === 'more') {
                    $("#search-results").append(
                        '<div class="more-link-li"><a class="more-link" href="' + value['url'] + '">' + value['text'] + '</a></div>'
                    );

                } else {
                    $("#search-results").append(
                        '<div class="search-result-row">' +
                          '<a href="' + value['url'] + '">' +
                              '<div class="search-result-icon">' +
                                  '<i class="fa ' + value['icon'] + ' fa-xl"></i>' +
                              '</div>' +
                              '<span class="search-result-text"' +
                                 (!value['description'].length ? 'style="margin-top: 10px;"' : '') +
                             '>' +
                                    value['text'] +
                              '</span>' +
                              '<span class="badge ' + value['labelClass'] + '">' +
                                  value['labelText'] +
                              '</span>' +
                              '<p>' +
                                  value['description'] +
                              '</p>' +
                          '</a>' +
                        '</div>'
                    );
                }
            });
        }
        $('#search-results-dropdown').addClass('show');
    }

}

document.addEventListener("DOMContentLoaded", function() {
    var search = new globalSearch();
});