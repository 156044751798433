class WebshopApi {
    request(url, params, onSuccessFunction) {
        const dfd = new $.Deferred();

        $.fancybox.showLoading();
        console.log('webshopApi.request.start', url, params);
        $.ajax({
            url: url,
            data: JSON.stringify(params),
            dataType: 'json',
            method: 'post',
            success: typeof onSuccessFunction === 'function' ? onSuccessFunction : false
        }).done(function (response) {
            dfd.resolve(response);
            console.log('webshopApi.request.done', url);
        }).always(function () {
            $.fancybox.hideLoading();
            dfd.reject();
            console.log('webshopApi.request.finished', url);
        });

        return dfd.promise();
    }
}

window.webshopApi = new WebshopApi();
