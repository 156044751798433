class TouchOrderService {
    apiPath = '/api/order';
    
    createShipment(order_id, token) {
        return webshopApi.request(this.apiPath + '/shipment/create', {
            order_id: order_id,
            token: token
        });
    }
    
    cancel(order_id, token) {
        return webshopApi.request(this.apiPath + '/cancel', {
            order_id: order_id,
            token: token
        });
    }
    
    printPackingList(order_id, token) {
        return webshopApi.request(this.apiPath + '/packing/printer', {
            order_id: order_id,
            token: token
        });
    }

    printLabel(barcode, token) {
        return webshopApi.request(this.apiPath + '/shipment/label/printer', {
            barcode: barcode,
            token: token
        });
    }

    updateStatus(order_id, token, status) {
        return webshopApi.request(this.apiPath + '/status/update', {
            order_id: order_id,
            token: token,
            status: status
        });
    }

    createNote(order_id, token, description) {
        return webshopApi.request(this.apiPath + '/note/create', {
            order_id: order_id,
            token: token,
            description: description
        });
    }
}

window.touchOrderService = new TouchOrderService();