window.datatableBulkActions = {
    init: function () {
        const self = this;
        $(document).on('submit', '#bulkActionsForm', function (e) {
            const hasSelection = self.hasSelection();
            if (!hasSelection) {
                e.preventDefault();
                self.showNoSelectionWarning();
                return false;
            }

            return true;
        });

        $(document).on('change', 'input[name*="dt-checkbox"]', function (e) {
            const selected = [];
            $('input[name*="dt-checkbox"]:checked').each(function () {
                selected.push($(this).data('id'));
            });
            $('#bulkSelectedIds').val(selected.join(','));
        });
    },
    hasSelection: function () {
        return !($('#bulkOption').find(":selected").val() === 'selection' && $('#bulkSelectedIds').val() === '');
    },
    showNoSelectionWarning: function () {
        bootbox.alert('Er zijn geen items aangevinkt.');
    }
}

datatableBulkActions.init();