require('bootstrap')
require('bootstrap/dist/css/bootstrap.css')

// Bootstrap select
require('bootstrap-select')
require('bootstrap-select/dist/css/bootstrap-select.css')
require('bootstrap-select/dist/js/i18n/defaults-nl_NL')

// Bootstrap toggle
require('bootstrap-toggle/js/bootstrap-toggle.min.js');
require('bootstrap-toggle/css/bootstrap-toggle.min.css');

// Bootbox
window.bootbox = require('bootbox');
bootbox.addLocale('nl', {
    OK      : 'Ok',
    CANCEL  : 'Nee',
    CONFIRM : 'Ja'
});
bootbox.setLocale('nl');
