class Touch {
    init() {
        const self = this;

        $('[data-touch-order-create-shipment]').click(function(e) {
            e.preventDefault();

            const id = $(this).data('touch-order-create-shipment');
            const token = $(this).data('token');

            new Promise(function(resolve) {
                self.showConfirmationModal(
                    'Order #' + id + ' verzenden',
                    null,
                    resolve
                );
            }).then(function() {
                return touchOrderService
                    .createShipment(id, token)
                    .then(function(response) {
                        if (response.status === 'ok') {
                            self.hideConfirmationModal();
                            self.showDialog('Order #' + id + ' verzonden');
                            self.removeTouchOrder(id);
                        } else {
                            self.hideConfirmationModal();
                            self.showErrorDialog(response.errors[0]);
                        }
                    });
            });
        });

        $('[data-touch-order-move-backorder]').click(function(e) {
            e.preventDefault();

            const id = $(this).data('touch-order-move-backorder');
            const token = $(this).data('token');

            new Promise(function(resolve) {
                self.showConfirmationModal(
                    'Order #' + id + ' naar backorder verplaatsen',
                    null,
                    resolve
                );
            }).then(function() {
                return touchOrderService
                    .updateStatus(id, token, 'PENDING_BACK_ORDER')
                    .then(function(response) {
                        if (response.status === 'ok') {
                            self.hideConfirmationModal();
                            self.showDialog('Order #' + id + ' naar backorder verplaatst');
                            self.removeTouchOrder(id);
                        } else {
                            self.hideConfirmationModal();
                            self.showErrorDialog(response.errors[0]);
                        }
                    });
            });
        });

        $('[data-touch-order-move-backorder-long]').click(function(e) {
            e.preventDefault();

            const id = $(this).data('touch-order-move-backorder-long');
            const token = $(this).data('token');

            new Promise(function(resolve) {
                self.showConfirmationModal(
                    'Order #' + id + ' naar backorder langlopend verplaatsen',
                    null,
                    resolve
                );
            }).then(function() {
                return touchOrderService
                    .updateStatus(id, token, 'PENDING_BACK_ORDER_LONG')
                    .then(function(response) {
                        if (response.status === 'ok') {
                            self.hideConfirmationModal();
                            self.showDialog('Order #' + id + ' naar backorder langlopend verplaatst');
                            self.removeTouchOrder(id);
                        } else {
                            self.hideConfirmationModal();
                            self.showErrorDialog(response.errors[0]);
                        }
                    });
            });
        });

        $('[data-touch-order-move-shipment]').click(function(e) {
            e.preventDefault();

            const id = $(this).data('touch-order-move-shipment');
            const token = $(this).data('token');

            new Promise(function(resolve) {
                self.showConfirmationModal(
                    'Order #' + id + ' naar verzenden verplaatsen',
                    null,
                    resolve
                );
            }).then(function() {
                return touchOrderService
                    .updateStatus(id, token, 'PENDING_SHIPMENT')
                    .then(function(response) {
                        if (response.status === 'ok') {
                            self.hideConfirmationModal();
                            self.showDialog('Order #' + id + ' naar verzenden verplaatst');
                            self.removeTouchOrder(id);
                        } else {
                            self.hideConfirmationModal();
                            self.showErrorDialog(response.errors[0]);
                        }
                    });
            });
        });

        $('[data-touch-order-move-customization]').click(function(e) {
            e.preventDefault();

            const id = $(this).data('touch-order-move-customization');
            const token = $(this).data('token');

            new Promise(function(resolve) {
                self.showConfirmationModal(
                    'Order #' + id + ' naar maatwerk verplaatsen',
                    null,
                    resolve
                );
            }).then(function() {
                return touchOrderService
                    .updateStatus(id, token, 'PENDING_CUSTOMIZATION')
                    .then(function(response) {
                        if (response.status === 'ok') {
                            self.hideConfirmationModal();
                            self.showDialog('Order #' + id + ' naar maatwerk verplaatst');
                            self.removeTouchOrder(id);
                        } else {
                            self.hideConfirmationModal();
                            self.showErrorDialog(response.errors[0]);
                        }
                    });
            });
        });

        $('[data-touch-order-print-packing-list]').click(function(e) {
            e.preventDefault();

            const id = $(this).data('touch-order-print-packing-list');
            const token = $(this).data('token');

            return touchOrderService
                .printPackingList(id, token)
                .then(function(response) {
                    if (response.status === 'ok') {
                        self.showDialog('Order #' + id + ' pakbon geprint')
                    } else {
                        self.showErrorDialog(response.errors[0]);
                    }
                });
        });

        $('[data-touch-order-add-note]').click(function(e) {
            e.preventDefault();

            const id = $(this).data('touch-order-add-note');
            const token = $(this).data('token');

            const modal = self.showDialog('Order #' + id + ' notitie toevoegen', '<form method="" action="post" data-touch-order-add-note-form><textarea name="description" class="form-control" rows="4" required></textarea><button type="submit" class="btn btn-primary mt-3">Notitie toevoegen</button></form>');
            modal.find('[data-modal-footer]').hide();

            $('[data-touch-order-add-note-form]').submit(function(e) {
                e.preventDefault();

                const description = $(this).find('[name="description"]').val();

                return touchOrderService
                    .createNote(id, token, description)
                    .then(function(response) {
                        if (response.status === 'ok') {
                            self.hideConfirmationModal();
                            self.showDialog('Order #' + id + ' notitie toegevoegd');
                        } else {
                            self.hideConfirmationModal();
                            self.showErrorDialog(response.errors[0]);
                        }
                    });
            });
        });

        $('[data-touch-order-cancel]').click(function(e) {
            e.preventDefault();

            const id = $(this).data('touch-order-cancel');
            const token = $(this).data('token');

            new Promise(function(resolve) {
                self.showConfirmationModal(
                    'Order #' + id + ' annuleren',
                    null,
                    resolve
                );
            }).then(function() {
                return touchOrderService
                    .cancel(id, token)
                    .then(function(response) {
                        if (response.status === 'ok') {
                            self.hideConfirmationModal();
                            self.showDialog('Order #' + id + ' geannuleerd');
                            self.removeTouchOrder(id);
                        } else {
                            self.hideConfirmationModal();
                            self.showErrorDialog(response.errors[0]);
                        }
                    });
            });
        });

        $('[data-touch-print-label]').click(function(e) {
            e.preventDefault();

            const barcode = $(this).data('touch-print-label');
            const token = $(this).data('token');

            return touchOrderService
                .printLabel(barcode, token)
                .then(function(response) {
                    if (response.status === 'ok') {
                        self.showDialog('Zending ' + barcode + ' label geprint')
                    } else {
                        self.showErrorDialog(response.errors[0]);
                    }
                });
        });

        $('[data-touch-refresh]').each(function() {
            let refreshTimer = setTimeout(function() { location.reload(); }, 60000);

            $(document.body).bind("mousemove click keypress", function(e) {
                clearTimeout(refreshTimer);
                refreshTimer = setTimeout(function() { location.reload(); }, 60000);
            });
        });
    }

    showErrorDialog(error) {
        const modal = $('[data-info-modal]');
        modal.find('[data-modal-title]').html('Error');
        modal.find('[data-modal-body]').html(error);
        modal.modal('show');
    }

    showDialog(title, text) {
        const modal = $('[data-info-modal]');
        modal.find('[data-modal-title]').html(title);
        modal.find('[data-modal-body]').html(text ? text : '');
        modal.modal('show');

        return modal;
    }

    removeTouchOrder(id) {
        $('[data-touch-order="' + id + '"]').remove();

        // refresh counts
        $.get(location.path)
            .then(function(response) {
                const tabs = $(response).find('.tabs');
                $('.touch > .tabs').html(tabs);
            });
    }

    showConfirmationModal(title, body, resolve, reject) {
        const self = this;

        const modal = $('[data-confirmation-modal]');
        modal.find('[data-modal-title]').html(title);
        modal.find('[data-modal-body]').html(body);

        modal.find('[data-modal-confirm]')
            .html('Bevestigen')
            .unbind('click')
            .click(function(e) {
                e.preventDefault();

                $(this).unbind('click');

                resolve();
            });

        if (reject) {
            modal.find('[data-modal-close]')
                .html('Sluiten')
                .unbind('click')
                .click(function (e) {
                    e.preventDefault();

                    $(this).unbind('click');

                    reject();
                });
        } else {
            modal.find('[data-modal-close]')
                .html('Sluiten')
                .unbind('click')
                .click(function (e) {
                    e.preventDefault();

                    $(this).unbind('click');

                    self.hideConfirmationModal();
                });
        }

        modal.modal('show');
    }

    hideConfirmationModal() {
        const modal = $('[data-confirmation-modal]');
        modal.modal('hide');
    }
}

window.touch = new Touch();