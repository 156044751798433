window.buttonActions = {
    post: function (url, confirmMessage = null, params = null) {
        const self = this;
        if (confirmMessage) {
            bootbox.confirm(confirmMessage, function (result) {
                if(result) {
                    self.submit(url, params);
                }
            });
            return;
        }

        this.submit(url, params);
    },
    submit: function (url, params = null) {
        const form = document.createElement("form");
        form.setAttribute("method", 'post');
        form.setAttribute("action", url);

        if (params) {
            for (var key in params) {
                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);

                form.appendChild(hiddenField);
            }
        }
        $(form).appendTo('body').submit().remove();
    }
}