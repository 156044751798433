class CategorySelector {
    modelId = null;
    currentCategoryId = null;
    categoryViewApiUrl = null;

    init(modalId, categoryViewApiUrl) {
        this.modalId = modalId;
        this.categoryViewApiUrl = categoryViewApiUrl;

        const self = this;
        $(modalId).modal('show');

        $(modalId).on('click', '.breadcrumbs a', function (e) {
            e.preventDefault();
            const categoryId = $(this).data('category-id');
            self.showCategories(categoryId);
        });

        $(modalId).on('click', '.category-list-item a', function (e) {
            e.preventDefault();
            const categoryId = $(this).data('category-id');
            self.showCategories(categoryId);
        });
    }
    showCategories(categoryId = 0) {
        const self = this;
        this.currentCategoryId = categoryId;
        const contentElement = document.querySelector(self.modalId).querySelector('.content');
        const selectedLabel = document.getElementById('selected-product-category');
        const acceptButton = document.getElementsByClassName('btn-accept')[0];

        selectedLabel.style.display = categoryId === 0 ? 'none' : 'block';
        acceptButton.style.display = 'none';

        $.fancybox.showLoading();
        contentElement.innerHTML = '<b>Loading ...</b>'
        fetch(self.categoryViewApiUrl + '?getImages=true&category=' + categoryId)
            .then(res => res.text())
            .then(html => {
                contentElement.innerHTML = html;
                const categoryName = document.getElementById('current-category-name');
                if(categoryName) {
                    document.querySelector('#selected-product-category').querySelector('label').innerText = categoryName.value;
                    const hasSubcategories = document.getElementById('has-subcategories').value;
                    acceptButton.style.display = hasSubcategories == 1 ? 'none' : 'block';
                }
                $.fancybox.hideLoading();
            });
    }
    getCurrentCategoryId() {
        return this.currentCategoryId;
    }
}

window.categorySelector = new CategorySelector();