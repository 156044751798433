import { startStimulusApp } from '@symfony/stimulus-bridge';

export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./admin/controllers',
    true,
    /\.(j|t)sx?$/
));

require('./admin/components/jquery.js');
require('./admin/components/angular.js');
require('./admin/components/select2.js');
require('./admin/components/bootstrap.js');
require('./admin/components/datatables.js');
require('./admin/components/fontawesome.js')
require('./admin/components/global-search.js')
require('./admin/components/buttonActions.js');
require('./admin/components/datatableBulkActions.js');

require('./admin/classes/WebshopApi.js');
require('./admin/classes/TouchOrderService.js');
require('./admin/classes/Touch.js');
require('./admin/classes/CategorySelector.js');

require('./admin_app.less');

