require('jszip');
require('pdfmake');

require('datatables.net-bs5');
require('datatables.net-bs5/css/dataTables.bootstrap5.min.css');

require('datatables.net-buttons-bs5');

require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');

require('datatables.net-fixedcolumns-bs5');
require('datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css');

require('datatables.net-fixedheader-bs5');
require('datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5.min.css');

require('datatables.net-select-bs5');
require('datatables.net-select-bs5/css/select.bootstrap5.min.css');

// require('datatables.net-scroller-dt');
// require('datatables.net-scroller-dt/css/scroller.dataTables.min.css');

$.extend(true, $.fn.dataTable.defaults, {
    drawCallback: function () {
        $("body").tooltip({selector: '[data-toggle=tooltip]'});
    },
} );

document.addEventListener('DOMContentLoaded', function() {
    $('table.dataTable').each(function () {
        const currentTable = $(this);

        let table = currentTable.DataTable({
            'language': {
                'url': '/assets/vendor/datatables-lang/Dutch.json'
            },
            'pageLength': 50,
            'stateSave': true,
            'responsive': true,
            'fixedHeader': {
                'headerOffset': currentTable.data('header-offset') ?? 65
            }
        });
    });
});
