const $ = require('jquery');
global.$ = global.jQuery = $;

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/dist/themes/base/jquery-ui.min.css');

require('fancybox/dist/js/jquery.fancybox.pack.js');
require('fancybox/dist/css/jquery.fancybox.css');
